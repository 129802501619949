import React, {useEffect, useLayoutEffect, useState} from "react";
import tokenFromLS from "../../utils/tokenFromLS";
import {state as globalState} from "../../state";
import {ReactTabulator} from "react-tabulator";
import {useFetchUserPaypment} from "../../hooks/useFetchUser";
import {ModalContext, ModalProvider, useModal} from "../Modal/Modal";
import InputField from "../EditFields/Input/InputField";
import Select from "../EditFields/Select/Select";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";
import {useQuery} from "react-query";
import tokenfromLS from "../../utils/tokenFromLS";

const CBElementAccountBalance = React.forwardRef((props, ref) => {
  const [userFee, setUserFee] = useState([])

  function getUserFee() {
    const apiHost = process.env.REACT_APP_API_URI
    const resource = `${apiHost}/user/fee`
    const fetchOptions = {
      headers: {
        Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
      },
    }
    const fetchData = async () => {
      try {
        const response = await fetch(resource, fetchOptions)
        const ReturnData = await response.json()
        if (ReturnData.status === 'ok') {
          return ReturnData.data
        } else {
          console.log('error', ReturnData)
        }
      } catch (error) {
        console.log('error', error)
      }
    }
    return fetchData()
  }

  useEffect(() => {
    // console.log('useEffect', botData)
    getUserFee().then((data) => {
      setUserFee(data)
    })
  }, [])

  // Fetch options
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${tokenfromLS(globalState.token)}`,
    },
  }

  const resource_stats_balance_current = `${process.env.REACT_APP_API_URI}/account/stats/balance_current`
  const fetchAccount_stats_balance_current = () =>
    fetch(resource_stats_balance_current, fetchOptions)
      .then((res) => res.json())
      .then((out) => {
        return out
      })

  const { data: data_stats_balance_current } = useQuery(
    'account_stats_balance_current',
    fetchAccount_stats_balance_current,
    // { refetchInterval: 60000 }
  )

  useLayoutEffect(() => {
    // if HTML Element chartdiv_stats_trade exists
    if (!document.getElementById('chartdiv_stats_balance_current')) {
      return
    }

    let root_stats_balance_current = am5.Root.new('chartdiv_stats_balance_current')

    root_stats_balance_current.setThemes([am5themes_Animated.new(root_stats_balance_current)])

    let chart = root_stats_balance_current.container.children.push(
      am5percent.PieChart.new(root_stats_balance_current, {
        endAngle: 270,
      })
    );

    let series = chart.series.push(am5percent.PieSeries.new(root_stats_balance_current, {
      valueField: "value",
      categoryField: "category",
      innerRadius: am5.percent(0),
      outerRadius: am5.percent(80),
      legendLabelText: "[{fill}]{category}[/]",
      legendValueText: "[bold]{valuePercentTotal.formatNumber('0.00')}%[/]",
      // legendValueText: "[bold {fill}]{value}[/]",
      alignLabels: false,
      tooltip: am5.Tooltip.new(root_stats_balance_current, {
        pointerOrientation: "vertical",
        labelText: "[bold]{category}[/]\nPercentage: [bold]{valuePercentTotal.formatNumber('0.00')}%[/]\nAmount: {valueBase}\nUSD: {calcUSD.formatNumber('$#.##')}",
        fontColor: "#000000",
        fontSize: 12,
        fontWeight: "bold"
      })
    }));

    series.labels.template.adapters.add("y", function(y, target) {
      var dataItem = target.dataItem;
      if (dataItem) {
        var tick = dataItem.get("tick");
        if (dataItem.get("valuePercentTotal") < 5) {
          target.set("forceHidden", true);
          tick.set("forceHidden", true);
        } else {
          target.set("forceHidden", false);
          tick.set("forceHidden", true);
        }
        return y;
      }
    });

    // if data_stats_balance_current.calcUSD = 0 then show "nothing"
    if (data_stats_balance_current && data_stats_balance_current.calcUSD && data_stats_balance_current.calcUSD !== 0.0) {
      // Move over all subaccounts:
      let SubAccounts = Object.keys(data_stats_balance_current.data)
      let data = []
      for (let i = 0; i < SubAccounts.length; i++) {
        // Lets move over all coins:
        let Coins = Object.keys(data_stats_balance_current.data[SubAccounts[i]])
        for (let j = 0; j < Coins.length; j++) {
          // Ignore all Coins without "calcUSD" in the name:
          if (Coins[j].indexOf('calcUSD') === -1) {
            continue
          }
          let CoinName = Coins[j].slice(0, -7)
          if (CoinName === '') {
            continue
          }
          let dataEntry = {
            // category name without "calcUSD":
            category: CoinName,
            value: data_stats_balance_current.data[SubAccounts[i]][Coins[j]],
            valueBase: data_stats_balance_current.data[SubAccounts[i]][CoinName],
            calcUSD: data_stats_balance_current.data[SubAccounts[i]][CoinName + 'calcUSD'],
          }
          data.push(dataEntry)
        }
      }

      // Sort data by calcUSD:
      data.sort(function(a, b) {
        return b.calcUSD - a.calcUSD;
      })
      // Filter data to show only > 0.01$
      data = data.filter(function (el) {
        return el.calcUSD > 0.02;
      })

      series.data.setAll(data)

    } else {
      series.data.setAll([
        {category: "Nothing", value: 100.0},
      ]);
    }

    // series.labels.template.set("visible", false);
    // series.ticks.template.set("visible", false);
    series.labels.template.setAll({
      radius: 60,
      inside: true,
    })

    // Legend
    // let legend = chart.children.push(am5.Legend.new(root_stats_balance_current, {
    //   // Right align legend to chart's center
    //   width: am5.p100,
    //   paddingBottom: 15,
    //   align: "right",
    //   orientation: "vertical",
    // }));
    // legend.data.setAll(series.dataItems);
    // console.log(series.dataItems)
    let legend = chart.children.push(am5.Legend.new(root_stats_balance_current, {

      }
    ));
    legend.data.setAll(series.dataItems);


    chart.appear(1000, 100)

    return () => {
      root_stats_balance_current.dispose()
    }
  }, [data_stats_balance_current])

  return (
    <div>
      <div draggable="true" className="cb-element-draggable-header">Balance Overview</div>
      <div className="cb-element-content">
        <div className="cb-element-user-fee">
          <div
            id="chartdiv_stats_balance_current"
            style={{width: '100%', height: '400px'}}
          ></div>
        </div>
      </div>
    </div>
  )
})

export {CBElementAccountBalance}