// src/utils/CandleStore.js
class CandleStore {
  constructor() {
    this.latestCandle = null;
  }

  // Save candle data
  storeCandle(candles) {
    if (Array.isArray(candles)) {
      this.latestCandle = candles.reduce((latest, candle) => {
        return !latest || new Date(candle.time) > new Date(latest.time) ? candle : latest;
      }, null);
    } else {
      if (!this.latestCandle || new Date(candles.time) > new Date(this.latestCandle.time)) {
        this.latestCandle = candles;
      }
    }
  }

  // Get the last price
  getLastPrice() {
    if (this.latestCandle) {
      return this.latestCandle.close; // Close price
    }
    return null;
  }
}

export default CandleStore;