import React, {useContext, useEffect, useMemo, useState} from 'react'
import {GlobalContext} from '../../../state'
import 'react-tabulator/lib/styles.css' // required styles
import 'react-tabulator/lib/css/tabulator.min.css' // theme
import tokenFromLS from '../../../utils/tokenFromLS'
import {sanitizeAccountData} from "../../../utils/sanatizer";
import {useModal} from "../../Modal/Modal";
import {darkTheme} from "../../Layout/Layout";
import {Box, Button, darken, lighten, ListItemIcon, MenuItem, ThemeProvider, useTheme} from "@mui/material";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  useMaterialReactTable
} from "material-react-table";
import {Delete, Edit, PlayArrow, StopSharp} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

const AccountPage = () => {
  const {openModal} = useModal();

  // Global state
  const globalState = useContext(GlobalContext)
  // API Host Address
  const apiHost = process.env.REACT_APP_API_URI
  // Resource URI
  const resource = `${process.env.REACT_APP_API_URI}/account`

  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [refreshData, setRefreshData] = useState(false);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });

  const navigate = useNavigate();

  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL(
        resource,
      );

      const newSorting = sorting.map((sort) => {
        // Return:
        // { fieldname: 'asc', fieldname2: 'desc' }
        return {
          [sort.id]: sort.desc ? 'desc' : 'asc',
        };
      });
      const newFilters = columnFilters.map((filter) => {
        // Return:
        // { fieldname: 'value' }
        return {
          variable: filter.id,
          value: filter.value,
          operator: 'like',
        };
      });
      const bodydata = {
        page: pagination.pageIndex,
        per_page: pagination.pageSize,
        filter: newFilters ?? [],
        sort: newSorting,
      }
      const fetchOptions = {
        method: 'POST',
        body: JSON.stringify(bodydata),
        headers: {
          Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        }
      }

      try {
        const response = await fetch(url.href, fetchOptions);
        const json = await response.json();
        setData(sanitizeAccountData(json.data));
        setRowCount(json.maxcount ?? 0);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
      setRefreshData(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    refreshData,
  ]);

  const submitModalDelete = async (row, showModal = true) => {
    if (!row) {
      return
    }

    const id = row?.id
    if (!id) {
      return
    }

    try {
      let RequestURL = `${process.env.REACT_APP_API_URI}/account/${id}`
      const response = await fetch(RequestURL, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
      const json = await response.json()
      if (json.status === 'ok') {
        // Refresh data
        setRefreshData(true)

      } else {
        if (showModal) {
          openModal({
            content: json.message ?? 'An error occurred while deleting the account. Please try again later.',
            showSubmitButton: false,
            showDismissButton: true,
            dismissButtonTxt: 'Close',
            showFooter: true,
          })
        }
        return false
      }
    } catch (err) {
      if (showModal) {
        openModal({
          content: 'An error occurred while deleting the account. Please try again later. Error: ' + err,
          showSubmitButton: false,
          showDismissButton: true,
          dismissButtonTxt: 'Close',
          showFooter: true,
        })
      }
      return false
    }
  }

  const handleInsert = () => {
    console.log('Insert');
    navigate('/account/add');
  };

  const AccountTable = () => {
    const {openModal} = useModal();
    //should be memoized or stable
    const columns = useMemo(
      () => [
        {
          accessorKey: '_id',
          header: 'ID',
          size: 0,
          columnDefType: 'display'
        },
        {
          accessorKey: 'name',
          header: 'Name',
          size: 150,
        },
        {
          accessorKey: 'type',
          header: 'Type',
          size: 100,
        },
        {
          accessorKey: 'enabled',
          header: 'Enabled',
          size: 30,
          columnDefType: 'boolean',
          Cell: ({cell}) => {
            return cell.getValue() ? (
              <PlayArrow color="success"/>
            ) : (
              <StopSharp color="error"/>
            )
          }
        },
        {
          accessorKey: 'sandbox',
          header: 'Sandbox',
          size: 30,
          columnDefType: 'boolean',
          Cell: ({cell}) => {
            return cell.getValue() ? (
              <PlayArrow color="success"/>
            ) : (
              <StopSharp color="error"/>
            )
          }
        },
      ],
      [],
    );

    const theme = useTheme();
    theme.palette.mode = 'dark';

    //light or dark green
    const baseBackgroundColor = 'rgb(57,64,74)';
    // const baseBackgroundColor =
    //   theme.palette.mode === 'dark'
    //     ? 'rgb(34,34,34)'
    //     : 'rgb(255,255,255)';

    const table = useMaterialReactTable({
      columns,
      data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
      initialState: {
        columnVisibility: {_id: false},
        columnPinning: {
          left: ['mrt-row-expand', 'mrt-row-select'],
          right: ['mrt-row-actions'],
        },
        density: 'compact',
      },
      enableRowSelection: true,
      enableGlobalFilter: false,
      enableRowActions: true,
      enableExpandAll: false,
      getRowId: (row) => row._id,
      manualFiltering: true,
      manualPagination: true,
      manualSorting: true,
      muiToolbarAlertBannerProps: isError
        ? {
          color: 'error',
          children: 'Error loading data',
        }
        : undefined,
      onColumnFiltersChange: setColumnFilters,
      onPaginationChange: setPagination,
      onSortingChange: setSorting,
      rowCount,
      state: {
        columnFilters,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
        sorting,
      },

      muiCircularProgressProps: {
        color: 'secondary',
        thickness: 5,
        size: 55,
      },
      muiSkeletonProps: {
        animation: 'pulse',
        height: 28,
      },

      muiTableBodyProps: {
        sx: (theme) => ({
          '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
            {
              backgroundColor: darken(baseBackgroundColor, 0.1),
              color: '#e1eeff',
            },
          '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
            {
              backgroundColor: darken(baseBackgroundColor, 0.2),
              color: '#e1eeff',
            },
          '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
            {
              backgroundColor: lighten(baseBackgroundColor, 0.1),
              color: '#e1eeff',
            },
          '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
            {
              backgroundColor: darken(baseBackgroundColor, 0.2),
              color: '#e1eeff',
            },
        }),
      },
      mrtTheme: (theme) => ({
        baseBackgroundColor: baseBackgroundColor,
        draggingBorderColor: theme.palette.secondary.main,
      }),


      renderTopToolbar: ({table}) => {

        const handleDelete = () => {

          openModal({
            content: 'Do you really want to delete the selected account?',
            showSubmitButton: true,
            showDismissButton: true,
            showFooter: true,
            submitButtonTxt: 'Delete',
            submitFn: () => {
              table.getSelectedRowModel().flatRows.map((row) => {
                submitModalDelete(row, false)
              });
              setRefreshData(true)
              openModal({
                content: 'Selected account have been deleted.',
                showSubmitButton: false,
                showDismissButton: true,
                dismissButtonTxt: 'Close',
                showFooter: true,

              })
            },
          })
        }

        return (
          <Box
            sx={(theme) => ({
              backgroundColor: lighten(theme.palette.background.default, 0.05),
              display: 'flex',
              gap: '0.5rem',
              p: '8px',
              justifyContent: 'space-between',
            })}
          >
            <Box>
              <Box sx={{display: 'flex', gap: '0.5rem'}}>
                {/*// If data and a row exists, show the button:*/}
                {/*{data.length > 0 && (*/}
                  <Button
                    color="info"
                    disabled={
                      table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()
                    }
                    onClick={handleInsert}
                    variant="contained"
                  >
                    Insert
                  </Button>
                {/*)}*/}
                <Button
                  color="info"
                  disabled={
                    !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                  }
                  onClick={handleDelete}
                  variant="contained"
                >
                  Delete
                </Button>
              </Box>
            </Box>
            <Box sx={{display: 'flex', gap: '0.5rem', alignItems: 'center'}}>
              {/* import MRT sub-components */}
              <MRT_GlobalFilterTextField table={table} id="globalFilter" name="globalFilter"/>
              <MRT_ToggleFiltersButton table={table} id="globalFilterButton" name="globalFilterButton"/>
              <MRT_ShowHideColumnsButton table={table} id="showHideColumnsButton" name="showHideColumnsButton"/>
            </Box>
          </Box>
        );
      },
      renderRowActionMenuItems: ({closeMenu, row, table}) => [
        <MenuItem
          key={0}
          onClick={() => {
            closeMenu();
            navigate('/account/' + row.id);
          }}
          sx={{m: 0}}
        >
          <ListItemIcon>
            <Edit/>
          </ListItemIcon>
          Edit
        </MenuItem>,
        <MenuItem
          key={1}
          onClick={() => {
            openModal({
              content: 'Do you really want to delete the account ' + row.getValue('name') + '?',
              showSubmitButton: true,
              showDismissButton: true,
              showFooter: true,
              submitButtonTxt: 'Delete',
              submitFn: () => {
                submitModalDelete(row)
              },
            })
            closeMenu();
          }}
          sx={{m: 0}}
        >
          <ListItemIcon>
            <Delete/>
          </ListItemIcon>
          Delete
        </MenuItem>,
      ],
      muiTableBodyCellProps: ({cell}) => ({
        onClick: (event) => {
          if (cell.column.id === 'name') {
            navigate('/account/' + cell.row.original._id);
          }

        },
        onDoubleClick: (event) => {
          if (cell.column.id === 'name') {
            navigate('/account/' + cell.row.original._id);
          }
        },
        // Only on field name:
        style: {
          cursor: cell.column.id === 'name' ? 'pointer' : 'inherit',
        },
      }),
    });

    return (
      <div>
        {/*{data?.length === 0 && (*/}
        {/*  <Button*/}
        {/*    color="info"*/}
        {/*    // disabled={*/}
        {/*    //   table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()*/}
        {/*    // }*/}
        {/*    onClick={handleInsert}*/}
        {/*    variant="contained"*/}
        {/*  >*/}
        {/*    Insert*/}
        {/*  </Button>*/}
        {/*)}*/}
        <MaterialReactTable table={table}/>
      </div>
    );
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={darkTheme}>
        <div>
          <AccountTable/>
        </div>
      </ThemeProvider>
    </React.Fragment>

  )
};

export default AccountPage;
