import React, {useState, useEffect, useMemo, useContext} from 'react';
import {Box, Button, darken, lighten, ListItemIcon, MenuItem, ThemeProvider, Typography, useTheme} from "@mui/material";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  useMaterialReactTable
} from "material-react-table";
import {Delete, Edit, PlayArrow, StopSharp} from "@mui/icons-material";
import CryptoWidget from "../../Widget/CryptoWidget";
import tokenFromLS from "../../../utils/tokenFromLS";
import {sanitizeSmartBotSignalData} from "../../../utils/sanatizer";
import {useModal} from "../../Modal/Modal";
import {GlobalContext} from "../../../state";
import {useNavigate} from "react-router-dom";
import {darkTheme} from "../../Layout/Layout";


const SmartbotDetailSignalTable = ({ botId }) => {
  const {openModal} = useModal();

  // Global state
  const globalState = useContext(GlobalContext)
  // API Host Address
  const apiHost = process.env.REACT_APP_API_URI
  // Resource URI
  const resource = `${apiHost}/smartbot/${botId}/signals`

  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [refreshData, setRefreshData] = useState(false);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const url = new URL(
        resource,
        apiHost,
      );

      const newSorting = sorting.map((sort) => {
        // Return:
        // { fieldname: 'asc', fieldname2: 'desc' }
        return {
          [sort.date]: 'desc',
        };
      });
      const newFilters = columnFilters.map((filter) => {
        // Return:
        // { fieldname: 'value' }
        return {
          variable: filter.id,
          value: filter.value,
          operator: 'like',
        };
      });
      const bodydata = {
        page: pagination.pageIndex,
        per_page: pagination.pageSize,
        filter: newFilters ?? [],
        sort: newSorting,
      }
      const fetchOptions = {
        method: 'POST',
        body: JSON.stringify(bodydata),
        headers: {
          Authorization: `Bearer ${tokenFromLS(globalState.token)}`,
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        }
      }

      setIsError(false);
      try {
        const response = await fetch(url.href, fetchOptions);
        const json = await response.json();
        if (json.status === 'ok') {
          setData(sanitizeSmartBotSignalData(json.data));
          setRowCount(json.maxcount ?? 0);
        } else {
          setIsError(true);
          console.error(json);
        }
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsLoading(false);
      setIsRefetching(false);
      setRefreshData(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    refreshData
  ]);

  const SmartbotDetailSignalTableShow = () => {
    //should be memoized or stable
    const columns = useMemo(
      () => [
        {
          accessorKey: 'createdate',
          header: 'Date',
          size: 50,
          // columnDefType: 'display'
        },
        {
          accessorKey: 'name',
          header: 'Name',
          size: 150,
        },
        {
          accessorKey: 'symbol',
          header: 'Symbol',
          size: 200,
        },
        {
          accessorKey: 'action',
          header: 'Action',
          size: 50,
        },
      ],
      [],
    );

    const theme = useTheme();
    theme.palette.mode = 'dark';

    //light or dark green
    const baseBackgroundColor = 'rgb(57,64,74)';
    // const baseBackgroundColor =
    //   theme.palette.mode === 'dark'
    //     ? 'rgb(34,34,34)'
    //     : 'rgb(255,255,255)';

    const table = useMaterialReactTable({
      columns,
      data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
      initialState: {
        columnVisibility: {_id: false},
        columnPinning: {
          left: ['mrt-row-expand', 'mrt-row-select'],
          right: ['mrt-row-actions'],
        },
        density: 'compact',
      },
      enableRowSelection: true,
      enableGlobalFilter: false,
      enableRowActions: false,
      enableExpandAll: false,
      getRowId: (row) => row._id,
      manualFiltering: true,
      manualPagination: true,
      manualSorting: true,
      muiToolbarAlertBannerProps: isError
        ? {
          color: 'error',
          children: 'Error loading data',
        }
        : undefined,
      onColumnFiltersChange: setColumnFilters,
      onPaginationChange: setPagination,
      onSortingChange: setSorting,
      rowCount,
      state: {
        columnFilters,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
        sorting,
      },

      muiCircularProgressProps: {
        color: 'secondary',
        thickness: 5,
        size: 55,
      },
      muiSkeletonProps: {
        animation: 'pulse',
        height: 28,
      },

      muiTableBodyProps: {
        sx: (theme) => ({
          '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
            {
              backgroundColor: darken(baseBackgroundColor, 0.1),
              color: '#e1eeff',
            },
          '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
            {
              backgroundColor: darken(baseBackgroundColor, 0.2),
              color: '#e1eeff',
            },
          '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
            {
              backgroundColor: lighten(baseBackgroundColor, 0.1),
              color: '#e1eeff',
            },
          '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
            {
              backgroundColor: darken(baseBackgroundColor, 0.2),
              color: '#e1eeff',
            },
        }),
      },
      mrtTheme: (theme) => ({
        baseBackgroundColor: baseBackgroundColor,
        draggingBorderColor: theme.palette.secondary.main,
      }),



      renderTopToolbar: ({table}) => {

        return (
          <Box
            sx={(theme) => ({
              backgroundColor: lighten(theme.palette.background.default, 0.05),
              display: 'flex',
              gap: '0.5rem',
              p: '8px',
              justifyContent: 'space-between',
            })}
          >
            <Box>
              <Box sx={{display: 'flex', gap: '0.5rem'}}>
              </Box>
            </Box>
            <Box sx={{display: 'flex', gap: '0.5rem', alignItems: 'center'}}>
              {/* import MRT sub-components */}
              <MRT_GlobalFilterTextField table={table}/>
              <MRT_ToggleFiltersButton table={table}/>
              <MRT_ShowHideColumnsButton table={table}/>
            </Box>
          </Box>
        );
      },
      renderRowActionMenuItems: ({closeMenu, row, table}) => [
      ],
      muiTableBodyCellProps: ({cell}) => ({
      }),
      // muiExpandButtonProps: ({ row, table }) => ({
      //   onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      //   sx: {
      //     transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
      //     transition: 'transform 0.2s',
      //   },
      // }),
    });

    return (
      <MaterialReactTable table={table}/>
    );
  };


  return (
    <React.Fragment>
      <ThemeProvider theme={darkTheme}>
        <div>
          <SmartbotDetailSignalTableShow />
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default SmartbotDetailSignalTable;